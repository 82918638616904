/* ----- Font Styles: ----- */
@font-face {
    font-family: 'Beatrice';
    src: url('fonts/Beatrice-Bold.eot');
    src: url('fonts/Beatrice-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Beatrice-Bold.woff2') format('woff2'),
    url('fonts/Beatrice-Bold.woff') format('woff'),
    url('fonts/Beatrice-Bold.ttf') format('truetype'),
    url('fonts/Beatrice-Bold.svg#Beatrice-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'GT America';
    src: url('fonts/GTAmerica-Regular.eot');
    src: url('fonts/GTAmerica-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/GTAmerica-Regular.woff2') format('woff2'),
    url('fonts/GTAmerica-Regular.woff') format('woff'),
    url('fonts/GTAmerica-Regular.ttf') format('truetype'),
    url('fonts/GTAmerica-Regular.svg#GTAmerica-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GT America';
    src: url('fonts/GTAmerica-Medium.eot');
    src: url('fonts/GTAmerica-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/GTAmerica-Medium.woff2') format('woff2'),
    url('fonts/GTAmerica-Medium.woff') format('woff'),
    url('fonts/GTAmerica-Medium.ttf') format('truetype'),
    url('fonts/GTAmerica-Medium.svg#GTAmerica-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'GT America';
    src: url('fonts/GTAmerica-Bold.eot');
    src: url('fonts/GTAmerica-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/GTAmerica-Bold.woff2') format('woff2'),
    url('fonts/GTAmerica-Bold.woff') format('woff'),
    url('fonts/GTAmerica-Bold.ttf') format('truetype'),
    url('fonts/GTAmerica-Bold.svg#GTAmerica-Bold') format('svg');
    font-weight: 600;
    font-style: normal;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}
