@import url("@theme/vendor.css");

/* Global styles that Material UI doesn't handle go here */
html {
  font-size: 10px;
}

body {
  line-height: 1.38;
}

a {
  text-decoration: none;
  outline: 0;
  color: #000000;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:hover {
  color: #939597;
}

a:focus,
a:hover,
a:active {
  outline: none;
}

ol,
ul {
  margin: 0;
}

ul {
  padding: 0;
}

ol {
  padding: 0 0 0 2rem;
}

ol li,
ul li {
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block;
}

label {
  font-size: 1.2rem;
  font-weight: 500;
}

input {
  font-size: 16px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

#bottom-sheet > div:last-of-type,
#bottom-sheet-2 > div:last-of-type {
  max-width: 580px;
  margin: 0 auto;
}

iframe {
  color-scheme: normal;
}

/* for FullScreenLoader */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}
